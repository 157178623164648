
import STEPS from '@/data/form.json'

export default {
    data () {
        return {
            STEPS,
        }
    },
    computed: {
        currentStep () {
            return this.$route.params.step
        },
    },
}
