
import closeSvg from '@/assets/images/close.svg?raw'
import leafSvg from '@/assets/images/leaf.svg?raw'
import infoSvg from '@/assets/images/info.svg?raw'

export default {
    data () {
        return {
            closeSvg,
            icons: {
                leaf: leafSvg,
                info: infoSvg,
            },
        }
    },
    methods: {
        close () {
            const isDesktop = window.innerWidth > 768
            if (!isDesktop) { this.$store.dispatch('hideModal') }
        },
    },
}
