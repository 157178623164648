import structuredClone from '@ungap/structured-clone'
import STEPS from '@/data/form.json'

const DEFAULT_MODAL = {
    visible: false,
    icon: 'leaf',
    titleKey: 'DID_YOU_KNOW',
    text: '',
}

export const state = () => ({
    steps: structuredClone(STEPS),
    modal: structuredClone(DEFAULT_MODAL),
})

export const mutations = {
    SHOW_MODAL (state, { textKey, titleKey, icon }) {
        if (state.modal.textKey === textKey) {
            state.modal.visible = !state.modal.visible
        } else {
            state.modal.visible = false
            state.modal.icon = icon || DEFAULT_MODAL.icon
            state.modal.titleKey = titleKey || DEFAULT_MODAL.titleKey
            state.modal.textKey = textKey || DEFAULT_MODAL.text
            state.modal.visible = true
        }
    },
    HIDE_MODAL (state) {
        state.modal.visible = false
    },
    UPDATE_ANSWER (state, { stepIndex, sectionIndex, questionIndex, value }) {
        state.steps[stepIndex].sections[sectionIndex].questions[questionIndex].value = value
        state.steps[stepIndex].sections[sectionIndex].questions[questionIndex].untouched = false
    },
}

export const actions = {
    showModal (context, { textKey, titleKey, icon } = {}) {
        context.commit('SHOW_MODAL', { textKey, titleKey, icon })
    },
    hideModal (context) {
        context.commit('HIDE_MODAL')
    },
    updateAnswer (context, { stepIndex, sectionIndex, questionIndex, value }) {
        context.commit('UPDATE_ANSWER', { stepIndex, sectionIndex, questionIndex, value })
    },
}

export const getters = {
    steps: state => state.steps,
    modal: state => state.modal,
}
