import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1dbf851a&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNav: require('/Users/salvatorevaccarino/Development/aib/going-green/components/TopNav.vue').default,BackgroundImage: require('/Users/salvatorevaccarino/Development/aib/going-green/components/BackgroundImage.vue').default,DidYouKnow: require('/Users/salvatorevaccarino/Development/aib/going-green/components/DidYouKnow.vue').default})
