
export default {
    computed: {
        backgroundImagePath () {
            return this.$store.state
                ?.steps[this.$route.params.step - 1]
                ?.sections[this.$route.params.section - 1]
                ?.background || 'welcome.jpeg'
        },
    },
}
